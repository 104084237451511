<template>
  <div class="px-4 md:px-10 pt-14 md:pt-16 pb-8 w-full flex flex-col gap-y-4">
    <div
      class="flex flex-col items-center gap-y-6 sm:flex-row sm:justify-between"
    >
      <div class="flex justify-between">
        <span class="pe-4 text-body-big text-white opacity-50">
          {{ $t("Follow us on:") }}
        </span>
        <div class="flex items-center gap-x-4">
          <a href="https://www.facebook.com/adtvmena/" target="_blank">
            <Icon name="IconSocialFacebook" size="24"></Icon>
          </a>
          <a href="https://twitter.com/ADtvmena" target="_blank">
            <Icon name="IconSocialTwitter" size="24"></Icon>
          </a>
          <a href="https://www.instagram.com/ADtvmena/" target="_blank">
            <Icon name="IconSocialInstagram" size="24"></Icon>
          </a>
          <a href="https://www.youtube.com/@Adtvmena" target="_blank">
            <Icon name="IconSocialYoutube" size="24"></Icon>
          </a>
        </div>
      </div>
      <span class="text-body-big text-end text-white opacity-[0.87]">
        <NuxtLink :to="localePath('/available-on')" target="_blank">{{
          $t("availableonTitle")
        }}</NuxtLink>
      </span>
    </div>
    <div
      class="flex flex-col items-center gap-2 lg:flex-row lg:justify-between"
    >
      <div
        class="flex flex-wrap justify-center gap-4 text-body-medium text-white opacity-50"
      >
        <NuxtLink
          :to="localePath('/terms')"
          target="_blank"
          class="transition duration-75 cursor-pointer hover:underline underline-offset-2 hover:text-gray-200"
        >
          {{ $t("termTitle") }}
        </NuxtLink>
        <NuxtLink
          :to="localePath('/privacy')"
          target="_blank"
          class="transition duration-75 cursor-pointer hover:underline underline-offset-2 hover:text-gray-200"
        >
          {{ $t("privacyTitle") }}
        </NuxtLink>
        <a
          href="mailto:help@admedia.ae"
          class="transition duration-75 cursor-pointer hover:underline underline-offset-2 hover:text-gray-200"
        >
          {{ $t("Call Us") }}
        </a>

        <NuxtLink
          :to="localePath('/about-us')"
          target="_blank"
          class="transition duration-75 cursor-pointer hover:underline underline-offset-2 hover:text-gray-200"
        >
          {{ $t("whoTitle") }}
        </NuxtLink>
      </div>
      <div class="flex gap-x-2 text-body-medium text-white">
        <span class="text-body-medium text-white opacity-50 text-center">
          &copy;
        </span>
        <span class="text-center">
          {{ $t("All Rights Reserved.") }}
        </span>
        <span class="text-body-medium text-white opacity-50 text-center">
          {{ $t("Abu Dhabi Media") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
const localePath = useLocalePath();
</script>

<script>
export default { name: "Footer" };
</script>

<style lang="scss" scoped></style>
