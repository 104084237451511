import { defineStore } from "pinia";

export const useThumbnailStore = defineStore("thumbnail", {
  state: () => ({
    element: null,
    wrapperKey: null,
    media: null,
    bounding: null,
    cursorOnPopup: false,
  }),
});
