<template>
  <div class="relative">
    <div
      class="w-full flex justify-between items-center gap-x-6 px-4 md:px-6 lg:px-10 py-3 md:py-[14px] lg:py-[18px] bg-brand-greyblack-300"
    >
      <div class="flex flex-col md:flex-row w-full gap-2 md:gap-4 lg:gap-6">
        <div class="flex justify-between items-center">
          <div class="flex items-center gap-x-2 md:gap-x-4 lg:gap-x-6">
            <slot name="channel-toggler"></slot>
            <div class="widget__controls flex gap-x-2 lg:gap-x-4 items-center">
              <ButtonsIcon
                v-if="isLoading"
                :breakpoints="PLAYERICON_SIZES"
                :enable-hover="false"
                icon-name="IconSpinner"
                class="animate-spin disabled"
              ></ButtonsIcon>

              <template v-else>
                <ButtonsIcon
                  :icon-name="playbackIcon"
                  :breakpoints="PLAYERICON_SIZES"
                  :enable-hover="false"
                  @click.stop="store.togglePlayback()"
                ></ButtonsIcon>

                <div
                  v-if="
                    !store.player?.isLive() && radioStore.episodes?.length > 0
                  "
                  class="widget__skippers flex gap-x-2 lg:gap-x-4 items-center"
                >
                  <ButtonsIcon
                    v-if="radioStore.prevToWatch"
                    :breakpoints="PLAYERICON_SIZES"
                    :enable-hover="false"
                    :disabled="radioStore.isFirstEpisode"
                    icon-name="IconSkipPrev"
                    @click="traverseEpisode(-1)"
                  ></ButtonsIcon>
                  <ButtonsIcon
                    v-if="radioStore.nextToWatch"
                    :breakpoints="PLAYERICON_SIZES"
                    :enable-hover="false"
                    :disabled="radioStore.isLastEpisode"
                    icon-name="IconSkipNext"
                    @click="traverseEpisode(1)"
                  ></ButtonsIcon>
                </div>
              </template>
            </div>
            <UiTagLive
              v-if="useIsLive(radioStore.widgetStation.programs?.[0])"
            ></UiTagLive>
          </div>
          <div class="flex md:hidden items-center">
            <RadioWidgetPlayerInfoPodcastSpeed />
            <ButtonsIcon
              :breakpoints="PLAYERICON_SIZES"
              :enable-hover="false"
              icon-name="IconTimesWhite"
              is-dark
              class=""
              @click="onCloseWidget"
            ></ButtonsIcon>
          </div>
        </div>
        <template v-if="store.player && !isLoading">
          <RadioWidgetPlayerInfoLive
            v-if="store.player?.isLive()"
          ></RadioWidgetPlayerInfoLive>
          <RadioWidgetPlayerInfoPodcast v-else></RadioWidgetPlayerInfoPodcast>
        </template>
      </div>
      <div class="items-center gap-x-6 hidden md:flex">
        <RadioWidgetPlayerInfoPodcastSpeed
          v-if="!isLoading && !store.player?.isLive()"
        />
        <ButtonsIcon
          :breakpoints="PLAYERICON_SIZES"
          :enable-hover="false"
          icon-name="IconTimesWhite"
          is-dark
          @click="onCloseWidget"
        ></ButtonsIcon>
      </div>
    </div>
    <video
      id="radioWidget"
      class="-z-10 absolute end-0 bottom-0 w-12 aspect-video touch-none pointer-events-none"
    ></video>
  </div>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";
import { PLAYERS, PLAYERICON_SIZES } from "@/constants";
import { usePlayerStoreWithID } from "@/stores/playerWithId";

const { breakpoints, isXs } = useBreakpoints();
const { fetchPlayingInfo, fetchEpisodes } = useFetchAsset();

const radioStore = useRadioStore();
const psrFuncs = usePsrConfig();
const { whichTypeNeedDispatch } = useADtvGTM();
const { isPlusNeedPopup } = useNeedLoginPopup();

const store = usePlayerStoreWithID(PLAYERS.RADIO_PLAYER)();

const playbackIcon = ref();
const playingInfo = ref();
const isLoading = ref(true);
const eventsRegistered = ref(false);

const i18n = useI18n();

onMounted(async () => {
  let analyticsConfig;
  await fetchPlayConfig();

  store.initialize("radioWidget");
  registerEvents();

  if (
    radioStore?.widgetStation?.type == "Movie" ||
    radioStore?.widgetStation?.aggregatedVideo
  ) {
    analyticsConfig = psrFuncs.parseRadioVod();
  } else {
    analyticsConfig = psrFuncs.parseRadioSeries();
  }
  await store.loadMedia(
    {
      url: playingInfo.value.playingUrl,
    },
    {},
    {},
    analyticsConfig
  );

  await nextTick();

  isLoading.value = false;
});

const iconSize = computed(() => {
  if (breakpoints.smallerOrEqual("md").value) return 32;
  if (isXs.value) return 38;
  return 42;
});

const fetchPlayConfig = async () => {
  if (radioStore.widgetStation.isPodcast) {
    const { video_external_id, assetExternalId } = radioStore.widgetStation;
    const response = await fetchPlayingInfo(video_external_id, assetExternalId);

    playingInfo.value = {
      ...response,
      playingUrl: response.url,
    };

    return;
  }

  playingInfo.value = await useGetMethod("/api/biz/live/playinfo", {
    channelExternalId: radioStore.widgetStation.externalChannelId,
  });
};

const registerEvents = () => {
  if (eventsRegistered.value) return;
  eventsRegistered.value = true;
  watch(
    () => store.playbackState,
    (newVal) => {
      switch (newVal) {
        case "play":
        case "playing":
          isLoading.value = false;
          playbackIcon.value = store.player.isLive()
            ? "IconStopSquare"
            : "IconPause";
          break;
        case "paused":
          isLoading.value = false;
          playbackIcon.value = "IconPlay";
          break;

        default:
          break;
      }
    }
  );
};

const onCloseWidget = async () => {
  await store.player?.destroy();
  store.$reset();
  useRadioWidget().dismiss();
};

radioStore.$subscribe(async (mutation, _) => {
  if (mutation.type == "patch function") {
    let analyticsConfig;
    isLoading.value = true;
    await store.player?.reset();

    await fetchPlayConfig();

    if (!store.player) {
      store.initialize("radioWidget");
    }

    if (
      radioStore?.widgetStation?.type == "Movie" ||
      radioStore?.widgetStation?.aggregatedVideo
    ) {
      analyticsConfig = psrFuncs.parseRadioVod();
    } else {
      analyticsConfig = psrFuncs.parseRadioSeries();
    }

    await store.loadMedia(
      {
        url: playingInfo.value.playingUrl,
      },
      {},
      {},
      analyticsConfig
    );

    registerEvents();

    isLoading.value = false;
  }
});

const traverseEpisode = (step) => {
  const episodes = radioStore.sortedEpisodes;
  if (step === -1 && radioStore.isFirstEpisode) {
    return;
  }

  if (step === 1 && radioStore.isLastEpisode) {
    return;
  }

  const index = episodes?.findIndex(
    (e) => e.assetExternalId == radioStore.widgetStation.query.video_external_id
  );
  if (episodes[index + step] && isPlusNeedPopup(episodes[index + step])) {
    return;
  }
  radioStore.$patch((state) => {
    const nextEpisode = episodes[index + step];
    state.widgetStation = {
      ...nextEpisode,
      isPodcast: true,
    };
  });

  whichTypeNeedDispatch("radio", radioStore.widgetStation);
};
</script>

<script>
export default { name: "RadioWidgetPlayer" };
</script>

<style lang="scss" scoped>
:deep(.sqp-all-controls) {
  touch-action: none;
  pointer-events: none;
}
</style>
