<template>
  <div>
    <client-only>
      <div
        v-if="!hasSetLanguage"
        id="switcher"
        class="fixed z-[70] bottom-6 md:bottom-10 md:end-10 w-[calc(100%_-_16px)] md:w-[336px] rounded-2xl border border-white/10 bg-brand-greyblack-300/80 pt-5 px-10 pb-10 backdrop-blur-xl shadow-lg"
      >
        <div class="w-full flex flex-col gap-y-5">
          <div :dir="direction" class="w-full flex justify-between items-start">
            <span class="text-title-medium text-white opacity-[0.87]">
              {{ $t("languagePopupTitle") }}
            </span>
            <ButtonsIcon
              class="absolute top-3 end-3"
              iconName="IconTimesWhite"
              @click="onDismiss"
            ></ButtonsIcon>
          </div>
          <ButtonsCta class="variant-secondary w-full" @click="onSwitchLocale">
            <div class="w-full flex justify-center items-center gap-2">
              <span>{{ label }}</span>
              <UiObserverImage
                :lazyImg="`/static/jpg/flags-jpg/${languageFlag}.jpg`"
                class="me-1.5"
                tabindex="0"
              >
              </UiObserverImage>
            </div>
          </ButtonsCta>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script setup>
const { locale, t, setLocale } = useI18n();
import { notification, Button } from "ant-design-vue";

const languageFlag = computed(() => {
  return locale.value === "ar" ? "gb" : "ae";
});

const direction = computed(() => {
  return locale.value === "ar" ? "ltr" : "rtl";
});

const label = computed(() => {
  if (locale.value === "ar") {
    return t("Switch to English");
  }

  return t("languagePopupBtn");
});

const hasSetLanguage = ref();

const switchLanguage = (lang) => {
  setLocale(lang);
  localStorage.setItem("adtv-guest-language", lang);

  notification.config({
    rtl: lang === "ar",
  });

  message.config({
    rtl: lang === "ar",
  });

  const localeCookie = useCookie("locale");
  localeCookie.value = lang;

  hasSetLanguage.value = localStorage.getItem("adtv-guest-language") != null;
};

const onSwitchLocale = () => {
  const lang = locale.value === "ar" ? "en" : "ar";
  switchLanguage(lang);
};

const onDismiss = () => {
  switchLanguage(locale.value);
};

onMounted(() => {
  hasSetLanguage.value =
    process.client && localStorage.getItem("adtv-guest-language");
});
</script>

<style lang="scss" scoped></style>
