<template>
  <div
    class="playerinfo__podcast flex-1 w-full flex flex-col justify-center gap-y-2"
  >
    <div class="widget__playinginfo flex gap-x-2">
      <template
        v-if="Object.keys(radioStore.widgetStation).includes('tvShowReference')"
      >
        <h1 class="text-title-big text-white opacity-[0.87]">
          {{ radioStore.widgetStation.displayName }}
        </h1>
      </template>
      <h1 v-if="showName" class="text-title-big text-white opacity-[0.87]">
        |
      </h1>
      <h1 class="text-title-big text-white opacity-[0.87] line-clamp-1">
        {{ showName }}
      </h1>
    </div>
    <div class="flex items-center gap-x-2">
      <div
        dir="ltr"
        class="pt-1 progressbar__duration w-32 text-label-big text-white opacity-[0.65]"
      >
        <span>{{ store.elapsedTimeLabel }}</span> /
        <span>{{ store.totalTimeLabel }}</span>
      </div>
      <div id="podcast-progress-bar" class="progressbar__bar flex-1">
        <div
          ref="overbar"
          class="group relative h-1.5 bg-white/40 rounded-xl flex items-center"
          @click.stop="clickHandle"
        >
          <div
            id="played"
            ref="played"
            class="z-10 bg-brand-red rounded-s-xl h-full"
          ></div>
          <div
            id="indicatorPosition"
            ref="indicatorPosition"
            class="absolute w-4 h-4 rounded-full group-hover:scale-150 bg-brand-red transition duration-100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useResizeObserver } from "@vueuse/core";
import { useRadioStore } from "@/stores/radio";
import { PLAYERS } from "@/constants";

const overbar = ref(null);
const indicatorPosition = ref();
const played = ref();

const radioStore = useRadioStore();
const store = useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);

// handle the width of the overbar
useResizeObserver(overbar, (entries) => {
  const entry = entries[0];
  const { width } = entry.contentRect;
  store.overbarWidth = width;
});

const showName = computed(() => {
  if (Object.keys(radioStore.widgetStation).includes("aggregatedVideo")) {
    return radioStore.widgetStation.aggregatedVideo.name;
  }

  return radioStore.widgetStation.name;
});

onMounted(() => {
  watch(
    () => store.currentTime,
    () => {
      setPosition();
    }
  );
});

const setPosition = () => {
  const newPosition = store.indicatorPosition * 100;
  const newIndicatorPosition = store.indicatorPosition * 100;
  indicatorPosition.value.style.insetInlineStart = `${newIndicatorPosition}%`;
  played.value.style.width = `${newPosition}%`;
};

const clickHandle = (e) => {
  const wrapperDom = document.getElementById("podcast-progress-bar");
  const left = wrapperDom?.getBoundingClientRect()?.left || 0;
  store.scrubTo(e.clientX - left);
};
</script>

<script>
export default { name: "PlayerInfoPodcast" };
</script>

<style lang="scss" scoped></style>
