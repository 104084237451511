<template>
  <div class="w-full h-full flex flex-col">
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
      </Head>
      <Body
        id="body"
        class="relative bg-brand-greyblack-300 font-Abu overflow-x-hidden"
      >
        <div>
          <client-only>
            <Header v-if="isLgAndUp"></Header>
            <HeaderSm v-else></HeaderSm>
          </client-only>
        </div>
        <DetailWatcher></DetailWatcher>
        <main ref="main" class="relative flex flex-col flex-grow">
          <slot></slot>
        </main>
        <div
          id="teleported"
          ref="teleported"
          :class="{
            'z-50 w-full h-full top-0': isTeleportActive,
          }"
          class="absolute"
          @click="onOverlayClick"
        ></div>

        <PlayerOverlayBuilderTrailer v-if="trailerStore.trailerPlayinfo" />

        <UiLanguagePopup></UiLanguagePopup>
        <client-only>
          <Footer></Footer>
        </client-only>

        <RadioWidget
          v-if="radioStore.showWidget"
          class="z-[80] fixed bottom-0 start-0"
        ></RadioWidget>
      </Body>
    </Html>
    <!-- <div class="invisible xs:visible text-white">xs</div>
      <div class="invisible sm:visible text-white">sm</div>
      <div class="invisible md:visible text-white">md</div>
      <div class="invisible lg:visible text-white">lg</div>
      <div class="invisible xl:visible text-white">xl</div> -->
  </div>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";
import { useNavStore } from "@/stores/nav";
import { useThumbnailStore } from "@/stores/thumbnail";
import { useGTMStore } from "@/stores/gtm";
import { useTrailerStore } from "@/stores/trailer";
const radioStore = useRadioStore();
const route = useRoute();
const trailerStore = useTrailerStore();

const { isLgAndUp } = useBreakpoints();
const navStore = useNavStore();
const gtmStore = useGTMStore();

const { t } = useI18n();
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
});

// Add Canonical for all pages and update on Change
useCanonicalTag();
watch(
  () => route.path,
  () => {
    useCanonicalTag();
  }
);

const isTeleportActive = ref(false);

const thumbnailStore = useThumbnailStore();
thumbnailStore.$subscribe((mutation, state) => {
  const { bounding } = mutation?.payload || {};
  if (!bounding) {
    isTeleportActive.value = false;
    return;
  }

  isTeleportActive.value = true;
});

const onOverlayClick = () => {
  thumbnailStore.$reset();
  isTeleportActive.value = false;
};

if (process.client) {
  navStore.fetchChannels();
}

onMounted(() => {
  // track document scroll
  document.addEventListener("scroll", (e) => {
    const lastKnownScrollPosition = window.scrollY;

    let ticking = false;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        // doSomething(lastKnownScrollPosition);
        if (!thumbnailStore.cursorOnPopup) {
          thumbnailStore.$reset();
        }
        isTeleportActive.value = false;
        ticking = false;
      });

      ticking = true;
    }
  });
});

watch(
  () => route.fullPath,
  (newRoute) => {
    gtmStore.setIsKid(newRoute.includes("/kids"));
  }
);
</script>

<style lang="postcss" scoped>
.ant-modal-content {
  padding: 0 !important;
  background-color: unset !important;
  background-clip: padding-box !important;
  border: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  pointer-events: auto !important;
}

.ant-modal-mask {
  background-color: unset !important;
}

.background-gradient {
  background: radial-gradient(
      100% 80% at 100% 0%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      130% 100% at 0% 100%,
      rgba(208, 45, 46, 0.5) 0%,
      rgba(0, 0, 0, 0) 87.68%
    );
}
</style>
