<template>
  <div
    dir="ltr"
    class="w-full flex flex-col bg-brand-greyblack-300"
  >
    <Transition
      name="slide"
      mode="out-in"
    >
      <div
        v-if="showChannels"
        class="z-40 widget__channellist w-full"
      >
        <RadioHeroChannelListHorizontal
          :channels="channels"
          :carousel-options="carouselOptions"
          :emit-channel-change="true"
          @on-channel-change="onChannelChange"
          epg-path="/radio/epg"
        >
          <template #label>
            {{ $t("Schedule") }}
          </template>
        </RadioHeroChannelListHorizontal>
      </div>
    </Transition>
    <RadioWidgetPlayer class="widget__player w-full">
      <template #channel-toggler>
        <button
          class="z-10 flex items-center gap-x-2"
          @click="onToggleChannelClick"
        >
          <div class="rounded-md bg-white/30">
            <div
              :style="{
                backgroundImage: `url('${widgetImage}')`,
              }"
              :class="[
                radioStore.isPodcast
                  ? 'w-[72px] aspect-square'
                  : 'widget-small small aspect-video ',
              ]"
              class="thumbnail rounded-md bg-center bg-contain bg-no-repeat"
            ></div>
          </div>
          <template v-if="radioStore.isPodcast === false">
            <Icon
              v-if="showChannels"
              name="IconChevronDown"
              size="24"
            ></Icon>
            <Icon
              name="IconChevronUp"
              size="24"
            ></Icon>
          </template>
        </button>
      </template>
    </RadioWidgetPlayer>
  </div>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";
import { useToggle } from "@vueuse/core";

const route = useRoute();
const router = useRouter();

const radioStore = useRadioStore();
const [showChannels, toggleChannels] = useToggle();
const { FMVideoStart } = useADtvGTM();

const widgetImage = computed(() => {
  // primarily check for images in the current episode
  const images = useMediaAssets(radioStore.widgetStation || {});
  if (Object.values(images).length > 0)
    return radioStore.isPodcast ? images.squareimage : images.logo;

  // if empty, look for images in the series level.
  return radioStore.seriesDetail?.images.squareimage;
});

const channels = computed(() => {
  return radioStore.channels.map((c) => {
    const images = useMediaAssets(c);
    return {
      ...c,
      images,
      logo: images?.logo,
      hori: images?.horizontalimage,
    };
  });
});

const carouselOptions = computed(() => {
  return {
    breakpoints: {
      375: {
        spaceBetween: 8,
        slidesOffsetBefore: 16,
      },
      768: {
        spaceBetween: 12,
        slidesOffsetBefore: 32,
      },
      1024: {
        spaceBetween: 16,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
      },
      1025: {
        spaceBetween: 16,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
      },
    },
  };
});

const onToggleChannelClick = () => {
  if (radioStore.isPodcast) return;
  toggleChannels();
};

const onChannelChange = (channel) => {
  radioStore.$patch((state) => {
    state.widgetStation = channel;
  });

  const isLive = radioStore.widgetStation.programs?.[0]
    ? useIsLive(radioStore.widgetStation.programs?.[0])
    : true;
  FMVideoStart(
    radioStore.activeChannel,
    isLive,
    radioStore.widgetStation.programs?.[0]
  );

  router.replace({
    path: route.path,
    query: {
      station: channel.externalChannelId,
    },
  });
};

radioStore.$subscribe(async (mutation, _) => {
  if (mutation.type == "patch function") {
    showChannels.value = false;
  }
});
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.2s ease-in-out;
}

.slide-leave-active {
  animation: slide-out 0.2s ease-in-out;
}

.slide-leave-to,
.slide-enter-from {
  transform: translateY(0px);
}
@keyframes slide-in {
  0% {
    transform: translateY(100%);
    opacity: 0.1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0.1;
  }
}
</style>
