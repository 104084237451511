<template>
  <div
    class="playerinfo__live flex-1 w-full flex flex-col justify-center gap-y-2"
  >
    <div class="widget__playinginfo flex gap-x-2 text-button-medium sm:text-title-medium md:text-title-big">
      <h1 class=" text-white opacity-[0.87]">
        {{ radioStore.widgetStation.name }}
      </h1>
      <h1
        v-if="radioStore.widgetStation.programs[0]?.name"
        class=" text-white opacity-[0.87]"
      >
        |
      </h1>
      <h1 class=" text-white opacity-[0.87]">
        {{ radioStore.widgetStation.programs[0]?.name }}
      </h1>
    </div>
  </div>
</template>

<script setup>
import { useRadioStore } from "@/stores/radio";
const radioStore = useRadioStore();
</script>

<script>
export default { name: "PlayerInfoLive" };
</script>

<style lang="scss" scoped></style>
