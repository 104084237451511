<template>
  <Body
    :class="{ 'overflow-y-hidden': modalState }"
    class="relative bg-brand-greyblack-300 font-Abu"
  >
    <Transition name="fade-fast" mode="out-in">
      <div
        v-if="modalState"
        class="watcher__backdrop z-50 fixed start-0 top-0 flex items-start justify-center modal__overlay w-full h-full backdrop-blur-md overflow-y-auto"
        @click.stop="onClose"
        id="detail-modal"
      >
        <div class="watcher__overlay max-w-[1280px] w-full p-4 md:p-10 lg:p-20">
          <div
            class="w-full h-full md:min-h-[720px] rounded-2xl bg-brand-greyblack-300"
            @click.stop=""
          >
            <div class="relative w-full h-full rounded-2xl detail-gradient">
              <ButtonsIcon
                is-dark
                icon-name="IconTimesWhite"
                class="absolute z-30 end-6 md:end-10 top-3 md:top-6"
                @click="onClose"
              >
              </ButtonsIcon>
              <Detail :key="route.query.media"></Detail>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Body>
</template>

<script setup>
import { useDetailStore } from "@/stores/detail";
import { PLAYERS } from "@/constants";

const allPlayers = useAllPlayers();

const router = useRouter();
const route = useRoute();

const modalState = ref(false);

const detailsStore = useDetailStore();

const mediaDeterminator = computed(() => useMediaType(route.query));

onMounted(() => {
  validateRouteForDetailModal();
});

const validateRouteForDetailModal = () => {
  modalState.value = Object.keys(route.query).includes("media");

  if (modalState.value) {
    allPlayers.pauseAll();
  } else {
    // allPlayers.resume(PLAYERS.HERO_PLAYER);
    useCanonicalTag();
  }
};

const onClose = () => {
  modalState.value = false;
  detailsStore.$reset();

  router.replace({ query: {} });
};

/**
 * this should serve as the trigger to open the modal
 * on route change
 */
watch(
  () => route.query,
  () => {
    validateRouteForDetailModal();
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
.detail-gradient {
  background: radial-gradient(
      100% 40vh at 0% 0,
      rgba(208, 45, 46, 0.4) 0%,
      rgba(0, 0, 0, 0) 130%
    ),
    radial-gradient(
      100% 40% at 150% 50%,
      rgba(208, 45, 46, 0.4) 0%,
      rgba(0, 0, 0, 0) 130%
    ),
    radial-gradient(
      100% 50% at 20% 140%,
      rgba(208, 45, 46, 0.4) 0%,
      rgba(0, 0, 0, 0) 130%
    );
}

[dir="rtl"] {
  .detail-gradient {
    background: radial-gradient(
        100% 40vh at 110% 0,
        rgba(208, 45, 46, 0.4) 0%,
        rgba(0, 0, 0, 0) 130%
      ),
      radial-gradient(
        100% 40% at 0% 50%,
        rgba(208, 45, 46, 0.4) 0%,
        rgba(0, 0, 0, 0) 130%
      ),
      radial-gradient(
        100% 50% at 0% 140%,
        rgba(208, 45, 46, 0.4) 0%,
        rgba(0, 0, 0, 0) 130%
      );
  }
}
</style>
