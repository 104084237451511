<template>
  <tippy :arrow="false" theme="blur" interactive>
    <ButtonsIcon
      :breakpoints="PLAYERICON_SIZES"
      :enable-hover="false"
      iconName="IconPlaybackSpeed"
    ></ButtonsIcon>
    <template #content>
      <TippyMenu>
        <div class="w-full flex flex-col">
          <span
            class="bg-white/10 text-body-big text-white opacity-[0.87] text-center px-6 py-4"
          >
            {{ $t("Speed") }}
          </span>
          <div
            :key="key"
            v-for="(s, key) in speeds"
            class="px-6 py-3 flex justify-center items-center gap-x-4 cursor-pointer"
            @click="store.setSpeed(s.value)"
            :class="[{ 'bg-white/30': store.getSpeed == s.value }]"
          >
            <span class="text-body-big text-center text-white opacity-[0.87]">
              {{ s.label }}
            </span>
          </div>
        </div>
      </TippyMenu>
    </template>
  </tippy>
</template>

<script setup>
import { PLAYERS, PLAYERICON_SIZES } from "@/constants";
import { useRadioStore } from "@/stores/radio";
import { usePlayerStoreWithID } from "@/stores/playerWithId";

const radioStore = useRadioStore();

const store = usePlayerStoreWithID(PLAYERS.RADIO_PLAYER)();

const speeds = computed(() => {
  return [
    {
      label: "2x",
      value: 2.0,
    },
    {
      label: "1.5x",
      value: 1.5,
    },
    {
      label: "1x",
      value: 1.0,
    },
    {
      label: "0.5x",
      value: 0.5,
    },
  ];
});
</script>

<style lang="scss" scoped></style>
